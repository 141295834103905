import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Helmet from "react-helmet"
import "../../css/boostrap.css"
import "../../css/custom.css"
import appleTochIcon from "../../images/apple-touch-icon.png"
import fav16 from "../../images/favicon-16x16.png"
import fav32 from "../../images/favicon-32x32.png"
import WhatsAppIcon from "./WhatsAppIcon"

const Layout = ({
    title = "Digital marketing company in Chennai | Award-Winning Marketing agency - Billiontags",
    children,
    isheaderTransparent = false,
}) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={appleTochIcon}
                />
                <meta name="description" 
                content="Billiontags, the Top Digital marketing company in Chennai. We are experts at finding ultimate digital marketing solutions. Get your free demo now!" />
                <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
                <link rel="icon" type="image/png" sizes="16x16" href={fav16} />
            </Helmet>
            <Header isheaderTransparent={isheaderTransparent} />
            <main className={isheaderTransparent ? "" : "page-margin"}>
                {children}
            </main>
            <Footer />
            <WhatsAppIcon />
        </>
    )
}

export default Layout
