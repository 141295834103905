import React from "react"
import logoLight from "../../images/logo-white.png"
import logoDark from "../../images/logo-dark.png"

const Logo = ({ light = false, ...rest }) => {
    return (
        <img
            src={light ? logoLight : logoDark}
            alt="Billiontags logo"
            {...rest}
        />
    )
}

export default Logo
