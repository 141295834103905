import React, { useState, useEffect } from "react"
import Logo from "./Logo"
import { Link } from "gatsby"
import Typed from "react-typed"

const Header = ({ isheaderTransparent = false }) => {
    const [show, set] = useState(false)

    return (
        <HeaderCon isheaderTransparent={isheaderTransparent}>
            <div className="header-con container-fluid">
                <div className="d-flex justify-content-between align-items-stretch">
                    <div className="d-flex align-items-stretch header-height">
                        <Link to="/" className="d-flex align-items-center pr-3">
                            <Logo
                                className="header-logo header-logo-white"
                                light
                            />
                            <Logo className="header-logo header-logo-dark" />
                        </Link>
                        <div className="img-border-right" />
                        <div className="d-flex align-items-center">
                            <div className="f-16 pl-3">
                                IDEAS MADE{" "}
                                <Typed
                                    strings={["BETTER", "BIGGER"]}
                                    typeSpeed={200}
                                    loop={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={`nav-links ${show ? "d-block" : ""}`}
                        id="nav-links"
                    >
                        <ul className="nav m-0 p-0">
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/"
                                    activeClassName="nav-link-active"
                                >
                                    Home
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/company"
                                    activeClassName="nav-link-active"
                                >
                                    Company
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/strategy"
                                    activeClassName="nav-link-active"
                                >
                                    Strategy
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/platforms"
                                    activeClassName="nav-link-active"
                                >
                                    Platforms
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/services"
                                    activeClassName="nav-link-active"
                                >
                                    Services
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/products"
                                    activeClassName="nav-link-active"
                                >
                                    Products
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    className="nav-link"
                                    to="/contact"
                                    activeClassName="nav-link-active"
                                >
                                    Contact
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <button
                        className="btn ham-icon p-0"
                        onClick={() => set(!show)}
                        id="ham-btn"
                    >
                        <svg
                            viewBox="0 0 24 24"
                            width={24}
                            height={24}
                            stroke="currentColor"
                            strokeWidth={2}
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="css-i6dzq1"
                        >
                            <line x1={3} y1={12} x2={21} y2={12} />
                            <line x1={3} y1={6} x2={21} y2={6} />
                            <line x1={3} y1={18} x2={21} y2={18} />
                        </svg>
                    </button>
                </div>
            </div>
        </HeaderCon>
    )
}

const HeaderCon = ({ isheaderTransparent = false, children }) => {
    if (!isheaderTransparent) {
        return <header className={`header header-bg-white`}>{children}</header>
    }
    return <HeaderScrollCon>{children}</HeaderScrollCon>
}

const HeaderScrollCon = ({ children }) => {
    const [isWhite, set] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                set(true)
            } else {
                set(false)
            }
        }
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <header
            id="header"
            className={`header header-bg-${isWhite ? "white" : "init"}`}
        >
            {children}
        </header>
    )
}

export default Header
